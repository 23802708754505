import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import Header, {
	PrimaryLink as PrimaryLinkBase,
	LogoLink,
} from "components/headers/light.js";
import icon from "images/lra.svg";
import MaterialList from "components/cards/MaterialList.js";
import { useParams } from "react-router-dom";

const Info = tw.div`p-8 border-2 border-t-0 rounded-lg`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;
const StyledHeader = styled(Header)`
	${tw`p-0 max-w-none w-full`}
	${LogoLink} {
		${tw`text-black hover:border-gray-300 hover:text-gray-300`}
	}
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({
	navLinks = [],
	logoLink = (
		<LogoLink href="/home">
			<img src={icon} alt="logo" />
			LightRainAir
		</LogoLink>
	),
	headingText = "Group Service Material",
}) => {
	const [visible, setVisible] = useState(6);
	const onLoadMoreClick = () => {
		setVisible((v) => v + 6);
	};
	const [material, setMaterial] = useState([]);
	const { url } = useParams();

	const heading =
		url === "dgsns_education"
			? "Nourishing WOD Education"
			: "BB Creation Education";

	return <MaterialList table={url} headingText={heading} />;
};
