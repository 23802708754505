import React, { useEffect, useState, useRef } from "react"; // Import useRef here
import { Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import Header, {
	NavLink,
	NavLinks,
	PrimaryLink as PrimaryLinkBase,
	LogoLink,
	NavToggle,
	DesktopNavLinks,
} from "components/headers/light.js";
import icon from "images/lra.svg";
import { PrimaryButton } from "components/misc/Buttons.js";
import Watermark from "helpers/Watermark.js";
import { useParams } from "react-router-dom";
import supabase from "helpers/SupabaseClient";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { watermarkImageWithText } from "helpers/Watermark";
import { useAuth } from "helpers/Auth.js";

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900 mb-0 sm:mb-10 mb-5`;

const ButtonContainer = tw.div`flex justify-end`;
const LoadMoreButton = tw(PrimaryButton)`mt-4 mb-4`;
const StyledHeader = styled(Header)`
	${tw`pt-0 max-w-none w-full`}
	${DesktopNavLinks} ${NavLink}, ${LogoLink} {
		${tw`text-black hover:border-gray-300 hover:text-gray-300`}
	}
	${NavToggle}.closed {
		${tw`text-gray-100 hover:text-primary-500`}
	}
`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

const VideoContainer = styled.div`
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
	overflow: hidden;

	@media (max-width: 768px) {
		padding-bottom: 75%;
	}

	.swarm-fluid {
		width: 100%;
		height: 100%;
		object-fit: contain; /* Or cover, depending on your preference */
	}
`;

const Text = styled.div`
	${tw`text-lg  text-gray-800`}
	p {
		${tw`mt-2 leading-loose`}
	}
	h1 {
		${tw`text-3xl font-bold mt-10`}
	}
	h2 {
		${tw`text-2xl font-bold mt-8`}
	}
	h3 {
		${tw`text-xl font-bold mt-6`}
	}
	ul {
		${tw`list-disc list-inside`}
		li {
			${tw`ml-2 mb-3`}
			p {
				${tw`mt-0 inline leading-normal`}
			}
		}
	}
`;

export default ({}) => {
	const { user } = useAuth();
	const [userId, setUserId] = useState("Interesting");
	const { url } = useParams();
	const [material, setMaterial] = useState([]);
	var vid_key = "i";
	const videoRef = useRef(null);
	const setWatermarkWidth = async () => {
		const userID = await setUser();
		console.log(userID);
		try {
			// This function will be called when the entire page (including images and stylesheets) is fully loaded
			const elements = document.getElementsByClassName("vjs-watermark");
			// Check if elements exist
			if (elements.length > 0) {
				// Access the first element (assuming there's only one)
				console.log(elements);
				const watermark = elements[0];
				console.log(watermark);
				// check if element has children
				// if (element.children.length > 0) {
				// const watermark = element.getElementsByClassName("vjs-watermark-center")[0];
				console.log(watermark);
				if (userId !== "") {
					console.log(userID);
					watermark.setAttribute(
						"src",
						watermarkImageWithText(userID)
					);
					watermark.setAttribute(
						"style",
						"min-width: 80% !important; pointer-events: none;"
					);
				}
				console.log(watermark);
				console.log("Watermark width set.");
			}
			// } else {
			//   console.log("Element not found.");
			// }
			// });
		} catch (error) {
			console.log("Error in setWatermarkWidth:", error);
		}
	};

	async function setUser() {
		try {
			const { data, error } = await supabase
				.from("access_report")
				.select("*")
				.eq("email", user.email);

			console.log(data[0]);
			setUserId((prevUserId) => data[0].registration_number);
			return data[0].registration_number;
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		async function fetchData() {
			try {
				const { data, error } = await supabase
					.from("dgsns_education")
					.select("*")
					.eq("url", url);
				console.log(data);
				const words = data.filter(
					(item) => item.status === "published"
				);

				console.log(words);
				setMaterial(words);
			} catch (err) {
				console.error("Error fetching data:", err);
			}
		}
		fetchData();
	}, [url]);

	useEffect(() => {
		// ... (your existing useEffect code) ...

		// Disable fullscreen button (and potentially other controls)
		const videoElement = videoRef.current;
		if (videoElement) {
			videoElement.controlsList = "nodownload"; // Remove download button

			// Attempt to remove the fullscreen button (may not work in all browsers)
			const fullscreenButton = videoElement.querySelector(
				".vjs-fullscreen-control"
			);
			if (fullscreenButton) {
				fullscreenButton.style.display = "none";
			}

			// Detect and prevent fullscreen attempts via API
			videoElement.addEventListener("webkitendfullscreen", () => {
				if (document.webkitIsFullScreen) {
					document.webkitExitFullscreen();
				}
			});
		}
	}, []);

	useEffect(() => {
		const timeoutId = setTimeout(setWatermarkWidth, 2000);
		return () => clearTimeout(timeoutId);
	}, []);

	var title, content;
	console.log(material);
	const parse =
		material.length > 0
			? (() => {
					title = material[0].title;
					content = material[0].content;
					vid_key = material[0].video_key;
					return "";
			  })()
			: "";

	return (
		<AnimationRevealPage>
			<StyledHeader links={[]} logoIcon={icon} />
			<Container>
				<ContentWithPaddingXl>
					<HeadingRow>
						<Heading>
							{
								<>
									<HighlightedText>{title}</HighlightedText>
								</>
							}
						</Heading>
					</HeadingRow>
					{vid_key && vid_key.length > 3 && (
						<VideoContainer>
							<smartvideo
								ref={videoRef} // Add this ref
								className="swarm-fluid"
								controls
								playsInline
								webkit-playsinline
								src={
									vid_key.length === 11
										? `https://www.youtube.com/embed/${vid_key}?playsinline=1`
										: `swarmify://${vid_key}`
								}
							></smartvideo>
						</VideoContainer>
					)}
					<br />
					<br />
					<Text>
						<Watermark></Watermark>
						<ReactMarkdown rehypePlugins={[rehypeRaw]}>
							{content}
						</ReactMarkdown>
					</Text>
				</ContentWithPaddingXl>
			</Container>
		</AnimationRevealPage>
	);
};
