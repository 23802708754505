import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line

/* Inner Pages */
import LoginPage from "pages/Login.js";
import RegisterPage from "pages/Register.js";
import ForgotPasswordPage from "pages/ForgotPassword.js";
import VerifyEmailPage from "pages/EmailVerification";

import HomePage from "pages/home/HomePage.js";
import ChairmansWordsPage from "pages/chairmansWords/ChairmansWords.js";
import DisclaimerPage from "pages/Disclaimer.js";
import StudyMaterialPage from "pages/studyMaterial/StudyMaterial.js";
import StudyMaterialTextPage from "pages/studyMaterial/StudyMaterialText";
import StudyMaterialVideoPage from "pages/studyMaterial/StudyMaterialVideo";
import RevelationSpeechingPage from "pages/studyMaterial/RevelationSpeeching/RevelationSpeeching.js";
import GroupServiceMaterialPage from "pages/studyMaterial/GroupMaterial/GroupServiceMaterial.js";
import GroupServiceMaterialContent from "pages/studyMaterial/GroupMaterial/GroupServiceMaterialContent.js";
import SpecialEducationHQPage from "pages/studyMaterial/SpecialEducationHQ/SpecialEducationHQ";
import SpecialEducationHQContent from "pages/studyMaterial/SpecialEducationHQ/SpecialEducationHQContent";
import PrevExamsPage from "pages/studyMaterial/PrevExams/PrevExams";
import PrevExamsContent from "pages/studyMaterial/PrevExams/PrevExamsContent";
import WODEduList from "pages/WODEducation/WODEduList";
import WODEduMaterial from "pages/WODEducation/WODEduMaterial";
import WODEduContent from "pages/WODEducation/WODEduContent";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DutyGuidelines from "pages/dutyGuidelines/DutyGuidelines";
import Mindset from "pages/dutyGuidelines/Mindset";
import ChapterOne from "pages/dutyGuidelines/ChapterOne";
import ChapterTwo from "pages/dutyGuidelines/ChapterTwo";
import ChairmansWordsArticle from "pages/chairmansWords/ChairmansWordsArticle";
import { AuthProvider } from "helpers/Auth";
import ProtectedRoute from "helpers/ProtectedRoute";
import ServiceNotesPage from "pages/studyMaterial/ServiceNotes/ServiceNotes";
import ServiceNotesContent from "pages/studyMaterial/ServiceNotes/ServiceNotesContent";
import HeavenlyCultureEdu from "pages/studyMaterial/HeavenlyCultureEdu/HeavenlyCultureEdu";
import RevFulfillment from "pages/studyMaterial/RevFulfillment/RevFulfillment";
import RevMusical from "pages/studyMaterial/RevFulfillment/RevMusical";
import BBTraining from "pages/studyMaterial/BBTraining/BBTraining";
import ExpertBBTraining from "pages/studyMaterial/ExpertBbt/ExpertBbt";
import ExpertBBTrainingContent from "pages/studyMaterial/ExpertBbt/ExpertBbtContent";
import NewComersEdu from "pages/studyMaterial/NewComersEdu/NewComersEdu";
import PrelimExamEdu from "pages/studyMaterial/PrelimExamEdu/PrelimExamEdu";
import LifeGivingQuotes from "pages/studyMaterial/LifeGivingQuotes/LifeGivingQuotes";
import SCJQnA from "pages/SCJqna/SCJQnA.js";
import ServicePage from "pages/home/Service";
import PloughingFields from "pages/studyMaterial/PloughingFields/PloughingFields";
import SCJEbook from "pages/SCJebook/SCJebook";
import SCjebookContent from "pages/SCJebook/SCjebookContent";
import AdminGA from "pages/wodPages/adminGA/adminGA";
import AdminGAContent from "pages/wodPages/adminGA/adminGAContent";
import Theology from "pages/wodPages/mot/mot";
import TheologyContent from "pages/wodPages/mot/motContent";
import TheologyPlaylist from "pages/wodPages/mot/motPlaylists";
import InternalAffairs from "pages/wodPages/internalAffairs/internalAffairs";
import InternalAffairsPage from "pages/wodPages/internalAffairs/internalAffairsPage";
import InternalAffairsContent from "pages/wodPages/internalAffairs/iaContent";
import WodPassword from "pages/WodPassword";
import ActiveRoute from "helpers/ActiveRoute";
import ProfilePage from "pages/ProfilePage";
import FinancePage from "pages/FinancePage";
import HeavenlyInbox from "pages/HeavenlyInbox";
import RevSpeeching from "pages/studyMaterial/RevelationSpeeching/RevSpeeching";
import RevelationSpeechingPageContent from "pages/studyMaterial/RevelationSpeeching/RevSpeechingContent";
import VideoPage from "helpers/VideoPage";
import AdminVideos from "pages/wodPages/adminGA/AdminVideos";
import MotText from "pages/wodPages/mot/motText";
import MotVideos from "pages/wodPages/mot/motVideos";
import LogTable from "pages/home/LogTable";
import ActsBook from "pages/SCJebook/ActsBook/ActsBook";
import WiseQuestions from "pages/SCJebook/EBookByChapters/WiseQuestions";
import RevStudyMaterials from "pages/SCJebook/EBookByChapters/RevStudyMaterials";
import ActsBookContent from "pages/SCJebook/ActsBook/ActsBookContent";
import UnitedGrad from "pages/UnitedGradCompetion/UnitedGrad";
import FirstRegistration from "pages/UnitedGradCompetion/FirstRegistration";
import SecondRegistration from "pages/UnitedGradCompetion/SecondRegistration";
import EBBTQualified from "pages/UnitedGradCompetion/EBBTQualified";

import EvangelismCompetition from "pages/UnitedGradCompetion/EvangelismCompetition/EvangelismCompetition";
import LeaderboardListComponent from "pages/UnitedGradCompetion/components/LeaderboardListComponent";
import PastorsEvangelismCompetition from "pages/UnitedGradCompetion/PastorsEvangelismCompetition/PastorsEvangelismCompetition";
import GroupCard from "pages/UnitedGradCompetion/GroupCard";
import EvangelismScoreboard from "pages/EvangelismScoreboard/EvangelismScoreboard";
import EvangelismScoreboardGrid from "pages/EvangelismScoreboard/EvangelismScoreboardGrid";
import EvangelismScoreboardContainer from "pages/EvangelismScoreboard/EvangelismScoreboardContainer";
import ToolForm from "pages/NoticeboardForm/ToolForm";
import TrueFaithContent from "pages/studyMaterial/TrueFaith/TrueFaithContent";

export default function App() {
	// If you want to disable the animation just use the disabled `prop` like below on your page's component
	// return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
	return (
		<>
			<GlobalStyles />
			<Router>
				<AuthProvider>
					<Routes>
						<Route
							path="/"
							element={
								<ActiveRoute>
									<LoginPage />
								</ActiveRoute>
							}
						/>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/register" element={<RegisterPage />} />
						<Route
							path="/verify-email"
							element={<VerifyEmailPage />}
						/>
						<Route
							path="/disclaimer"
							element={<DisclaimerPage />}
						/>
						<Route
							path="/forgot-password"
							element={<ForgotPasswordPage />}
						/>
						<Route
							path="/reset-password"
							element={<ForgotPasswordPage mode="reset" />}
						/>

						{/* Need to be logged in */}
						<Route
							path="/home"
							element={
								<ProtectedRoute>
									<HomePage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/profile"
							element={
								<ProtectedRoute>
									<ProfilePage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/tithes-and-offerings"
							element={
								<ProtectedRoute>
									<FinancePage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/heavenly-inbox"
							element={
								<ProtectedRoute>
									<HeavenlyInbox />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/service"
							element={
								<ProtectedRoute>
									<ServicePage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/video-material/:url"
							element={
								<ProtectedRoute>
									<VideoPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/logs"
							element={
								<ProtectedRoute>
									<LogTable />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/video-material/:url"
							element={
								<ProtectedRoute>
									<VideoPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/duty-guidelines/"
							element={
								<ProtectedRoute>
									<DutyGuidelines />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/duty-guidelines/mindset/"
							element={
								<ProtectedRoute>
									<Mindset />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/duty-guidelines/chapter-1/"
							element={
								<ProtectedRoute>
									<ChapterOne />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/duty-guidelines/chapter-2/"
							element={
								<ProtectedRoute>
									<ChapterTwo />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/chairmans-words/"
							element={
								<ProtectedRoute>
									<ChairmansWordsPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/chairmans-life-giving-words/:url"
							element={
								<ProtectedRoute>
									<ChairmansWordsArticle />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/"
							element={
								<ProtectedRoute>
									<StudyMaterialPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/"
							element={
								<ProtectedRoute>
									<StudyMaterialTextPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/"
							element={
								<ProtectedRoute>
									<StudyMaterialVideoPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/rev-speeching"
							element={
								<ProtectedRoute>
									<RevSpeeching mat_type={"text"} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/rev-speeching/scj40"
							element={
								<ProtectedRoute>
									<RevelationSpeechingPage
										mat_type={"text"}
										year={"40"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/rev-speeching/scj41"
							element={
								<ProtectedRoute>
									<RevelationSpeechingPage
										mat_type={"text"}
										year={"41"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/rev-speeching"
							element={
								<ProtectedRoute>
									<RevSpeeching mat_type={"video"} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/rev-speeching/scj40"
							element={
								<ProtectedRoute>
									<RevelationSpeechingPage
										mat_type={"video"}
										year={"40"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/rev-speeching/scj41"
							element={
								<ProtectedRoute>
									<RevelationSpeechingPage
										mat_type={"video"}
										year={"41"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/rev-speech-education/"
							element={
								<ProtectedRoute>
									<RevelationSpeechingPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/rev-speech-education/"
							element={
								<ProtectedRoute>
									<RevelationSpeechingPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/revelation-speech-education-texts/:url"
							element={
								<ProtectedRoute>
									<RevelationSpeechingPageContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/revelation-speech-education-texts-41/:url"
							element={
								<ProtectedRoute>
									<RevelationSpeechingPageContent
										year={"_41"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/rev-speech-education/"
							element={
								<ProtectedRoute>
									<RevelationSpeechingPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/group-service-material/"
							element={
								<ProtectedRoute>
									<GroupServiceMaterialPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/group-service-material/:url"
							element={
								<ProtectedRoute>
									<GroupServiceMaterialContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/special-education-hq-for-saints/"
							element={
								<ProtectedRoute>
									<SpecialEducationHQPage type={"text"} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/special-education-texts/:url"
							element={
								<ProtectedRoute>
									<SpecialEducationHQContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/special-education-hq-for-saints/"
							element={
								<ProtectedRoute>
									<SpecialEducationHQPage type={"video"} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/prev-exams-study-material/"
							element={
								<ProtectedRoute>
									<PrevExamsPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/Study-Material-from-Previous-Exams/:url"
							element={
								<ProtectedRoute>
									<PrevExamsContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/service-notes/"
							element={
								<ProtectedRoute>
									<ServiceNotesPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/service-notes/:url"
							element={
								<ProtectedRoute>
									<ServiceNotesContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/true-faith-of-believers/"
							element={
								<ProtectedRoute>
									<TrueFaithContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/heavenly-culture-education/"
							element={
								<ProtectedRoute>
									<HeavenlyCultureEdu />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/prelim-exam-education/"
							element={
								<ProtectedRoute>
									<PrelimExamEdu />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/rev-documentary/"
							element={
								<ProtectedRoute>
									<RevFulfillment />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/rev-musical/"
							element={
								<ProtectedRoute>
									<RevMusical />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/bb-training/"
							element={
								<ProtectedRoute>
									<BBTraining />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/bbt-training/"
							element={
								<ProtectedRoute>
									<ExpertBBTraining />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/faq_for_open_ev/"
							element={
								<ProtectedRoute>
									<SCJQnA page={"faq_for_open_ev"} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/expert-bbt-training/:url"
							element={
								<ProtectedRoute>
									<ExpertBBTrainingContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/newcomers-education/"
							element={
								<ProtectedRoute>
									<NewComersEdu />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/text/CHJN-quotes/"
							element={
								<ProtectedRoute>
									<LifeGivingQuotes />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/study-material/video/ploughing-fields/"
							element={
								<ProtectedRoute>
									<PloughingFields />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/scj-qna/"
							element={
								<ProtectedRoute>
									<SCJQnA />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/scj-ebook/"
							element={
								<ProtectedRoute>
									<SCJEbook />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/scj-ebook/acts-of-jesus"
							element={
								<ProtectedRoute>
									<ActsBook />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/actsbook/:url"
							element={
								<ProtectedRoute>
									<SCjebookContent table={"actsbook"} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/scj-ebook/wise-questions"
							element={
								<ProtectedRoute>
									<WiseQuestions />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/wisequestions/:url"
							element={
								<ProtectedRoute>
									<SCjebookContent table={"wisequestions"} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/scj-ebook/rev-study-materials"
							element={
								<ProtectedRoute>
									<RevStudyMaterials />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/revstudymaterials/:url"
							element={
								<ProtectedRoute>
									<SCjebookContent
										table={"revstudymaterials"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/scj-ebook/:url"
							element={
								<ProtectedRoute>
									<SCjebookContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/wod-auth/:url"
							element={
								<ProtectedRoute>
									<WodPassword />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/admin-and-general-affairs/"
							element={
								<ProtectedRoute page={"admin"}>
									<AdminGA />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/admin-and-general-affairs/:url"
							element={
								<ProtectedRoute page={"admin"}>
									<AdminGAContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/admin-and-general-affairs/training-vids"
							element={
								<ProtectedRoute page={"admin"}>
									<AdminVideos />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/ministry-of-theology/"
							element={
								<ProtectedRoute page={"mot"}>
									<Theology />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/ministry-of-theology/text"
							element={
								<ProtectedRoute page={"mot"}>
									<MotText />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/ministry-of-theology/training-vids"
							element={
								<ProtectedRoute page={"mot"}>
									<MotVideos />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/ministry-of-theology/:url"
							element={
								<ProtectedRoute page={"mot"}>
									<TheologyContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/ministry-of-theology/playlist/:url"
							element={
								<ProtectedRoute page={"mot"}>
									<TheologyPlaylist />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/internal-affairs/"
							element={
								<ProtectedRoute page={"ia"}>
									<InternalAffairs />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/internal-affairs/:url"
							element={
								<ProtectedRoute page={"ia"}>
									<InternalAffairsPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/internal-affairs/:url/:url"
							element={
								<ProtectedRoute page={"ia"}>
									<InternalAffairsContent />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/actsbook-guideline/"
							element={
								<ProtectedRoute>
									<SCjebookContent
										table={"actsbook_guideline"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/actsbook-guideline/:url"
							element={
								<ProtectedRoute>
									<SCjebookContent
										table={"actsbook_guideline"}
									/>
								</ProtectedRoute>
							}
						/>

						<Route
							path="/wod-education/"
							element={
								<ProtectedRoute>
									<WODEduList />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/wod-education/:url"
							element={
								<ProtectedRoute>
									<WODEduMaterial />
								</ProtectedRoute>
							}
						/>

						<Route
							path="/dgsns-education/:url"
							element={
								<ProtectedRoute>
									<WODEduContent />
								</ProtectedRoute>
							}
						/>

						<Route
							path="/united-grad-competition"
							element={
								<ProtectedRoute>
									<UnitedGrad />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/united-grad-competition/evangelism"
							element={
								<ProtectedRoute>
									<EvangelismCompetition />
								</ProtectedRoute>
							}
						/>

						<Route
							path="/united-grad-competition/evangelism/fruit-whisperer"
							element={
								<ProtectedRoute>
									<GroupCard
										title={"Fruit Whisperer"}
										table={"best_networker"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/united-grad-competition/evangelism/fruit-captivator"
							element={
								<ProtectedRoute>
									<GroupCard
										title={"Fruit Captivator"}
										table={"best_bb_teacher"}
									/>
								</ProtectedRoute>
							}
						/>

						<Route
							path="/united-grad-competition/evangelism/first-registration"
							element={
								<ProtectedRoute>
									<LeaderboardListComponent
										title={"Center Opening Registration"}
										table={"center_opening_registration"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/united-grad-competition/evangelism/second-registration"
							element={
								<ProtectedRoute>
									<LeaderboardListComponent
										title={"Intermediate Registration "}
										table={"intermediate_registration"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/united-grad-competition/evangelism/ebbt-qualified"
							element={
								<ProtectedRoute>
									<LeaderboardListComponent
										title={"EBBT Qualified"}
										table={"ebbt_qualified"}
										filter={"lessons"}
									/>
								</ProtectedRoute>
							}
						/>

						<Route
							path="/united-grad-competition/pastors-evangelism/pastors-in-co"
							element={
								<ProtectedRoute>
									<LeaderboardListComponent
										title={"Pastors in CO"}
										table={"modm_stats"}
										filter={"lessons"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/united-grad-competition/pastors-evangelism/pastors-teaching"
							element={
								<ProtectedRoute>
									<LeaderboardListComponent
										title={"Pastors Teaching"}
										table={"modm_stats"}
									/>
								</ProtectedRoute>
							}
						/>

						<Route
							path="/united-grad-competition/pastors-evangelism"
							element={
								<ProtectedRoute>
									<PastorsEvangelismCompetition />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/united-grad-competition/pastors-evangelism/lessons-taught"
							element={
								<ProtectedRoute>
									<LeaderboardListComponent
										title={"Lessons Taught"}
										table={"scj_missionaries_stats"}
										filter={"lessons"}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/united-grad-competition/pastors-evangelism/pastor-referrals"
							element={
								<ProtectedRoute>
									<LeaderboardListComponent
										title={"Pastor Referrals"}
										table={"scj_missionaries_stats"}
									/>
								</ProtectedRoute>
							}
						/>

						<Route
							path="/noticeboard"
							element={
								<ProtectedRoute>
									<EvangelismScoreboard />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/noticeboard/:url"
							element={
								<ProtectedRoute>
									<EvangelismScoreboardGrid />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/noticeboard/:url/:url"
							element={
								<ProtectedRoute>
									<EvangelismScoreboardContainer />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/noticeboard/tool-form"
							element={
								<ProtectedRoute>
									<ToolForm />
								</ProtectedRoute>
							}
						/>
					</Routes>
				</AuthProvider>
			</Router>
		</>
	);
}
