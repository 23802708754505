import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Header, {
	NavLink,
	NavLinks,
	PrimaryLink as PrimaryLinkBase,
	LogoLink,
	NavToggle,
	DesktopNavLinks,
} from "../../components/headers/light.js";
import tw from "twin.macro";
import { useAuth, checkWodAccess, SignOut } from "helpers/Auth.js";
import { useEffect, useState } from "react";
import { getCurrentDB } from "helpers/DB.js";

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;

export default () => {
	const { user } = useAuth();
	const [access, setAccess] = useState(null);
	const [dailybread, setDailyBread] = useState({
		title: "",
		video_key: "",
		date: "",
	});
	const [link, setLink] = useState("#");

	useEffect(() => {
		// if (user) {
		//   checkWodAccess(user.email)
		//     .then((result) => {
		//       console.log(result);
		//       if (!!result.WOD_page_access){
		//         setAccess([
		//           'Administration and General Affairs', 'Ministry of Theology', 'Internal Affairs'
		//         ])
		//       }
		//     })
		//     .catch((error) => {
		//       console.error('Error fetching access:', error);
		//       // Handle error if needed
		//     });
		// }
		setAccess([
			"Administration and General Affairs",
			"Ministry of Theology",
			"Internal Affairs",
		]);
		handleData();
	}, []);

	const handleData = async () => {
		const db = await getCurrentDB();
		console.log(db);
		setDailyBread(db !== undefined ? db : dailybread);
		setLink(
			db === undefined
				? link
				: `/video-material/${db.video_key}-->${db.date} Daily Bread`
		);
	};

	return (
		<AnimationRevealPage>
			<Hero
				cta={[
					{ header: "SERVICE VIDEO", route: "/service" },
					{ header: `DAILY BREAD`, route: link },
					{ header: "DUTY GUIDELINE", route: "/duty-guidelines" },
					{
						header: "CHAIRMAN'S LIFE GIVING WORDS",
						route: "/chairmans-words/",
					},
					{ header: "STUDY MATERIAL", route: "/study-material/" },
					{ header: "SHINCHEONJI E-BOOK", route: "/scj-ebook/" },
					{ header: "SHINCHEONJI Q&A", route: "/scj-qna/" },
					{ header: "WOD EDUCATION", route: "/wod-education/" },
					// {
					// 	header: "EVANGELISM NOTICEBOARD",
					// 	route: "/noticeboard/",
					// },
					// { header: "LET'S GO TOGETHER", route: "/united-grad-competition/"},
				]}
				navs={access}
			/>
		</AnimationRevealPage>
	);
};
