import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import { ReactComponent as PendingIcon } from "feather-icons/dist/icons/circle.svg";
import { ReactComponent as DoneIcon } from "feather-icons/dist/icons/check-circle.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-2 lg:py-12`;

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`text-center lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQContainer = tw.div`mt-8 border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
	${tw`cursor-pointer ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
	svg {
		${tw`w-4 h-4`}
	}
`;
// Removed pointer-events-none from the Answer component
const Answer = motion(styled.div`
	${tw`text-sm sm:text-base leading-relaxed`}
	white-space: pre-line;
`);

const FAQ = ({
	subheading = "",
	description = "",
	faqs = null,
	categories = [
		"Tithe",
		"Service",
		"Thanksgiving",
		"Construction",
		"Mission",
		"Special Offering",
	],
	toggleClick = true,
}) => {
	/*
	 * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
	 * the faqs prop
	 */
	const defaultFaqs = [];

	if (!faqs || faqs.length === 0) faqs = defaultFaqs;
	console.log(faqs);
	console.log(faqs[0] ? faqs[0].answer : "");
	console.log(faqs[0] ? typeof faqs[0].answer : "");

	const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

	const toggleQuestion = (questionIndex) => {
		if (activeQuestionIndex === questionIndex && toggleClick)
			setActiveQuestionIndex(null);
		else setActiveQuestionIndex(questionIndex);
	};

	return (
		<Container>
			<Content>
				<FAQContent>
					{subheading ? <Subheading>{subheading}</Subheading> : ""}
					<Description>{description}</Description>
					<FAQSContainer>
						{faqs.map((faq, index) => (
							<FAQContainer
								onClick={() => {
									toggleQuestion(index);
								}}
								key={index}
								className="group"
							>
								<Question>
									<QuestionText>{faq.question}</QuestionText>
									<QuestionToggleIcon>
										{activeQuestionIndex === index ? (
											<MinusIcon />
										) : (
											<PlusIcon />
										)}
									</QuestionToggleIcon>
								</Question>
								<Answer
									variants={{
										open: {
											opacity: 1,
											height: "auto",
											marginTop: "16px",
											pointerEvents: "auto", // Enable pointer events when open
										},
										collapsed: {
											opacity: 0,
											height: 0,
											marginTop: "0px",
											pointerEvents: "none", // Disable pointer events when collapsed
										},
									}}
									initial="collapsed"
									animate={
										activeQuestionIndex === index
											? "open"
											: "collapsed"
									}
									transition={{
										duration: 0.3,
										ease: [0.04, 0.62, 0.23, 0.98],
									}}
								>
									{typeof faq.answer === "string" ? (
										faq.answer.trim()
									) : Array.isArray(faq.answer) &&
									  faq.answer.every(
											(a) => typeof a === "object"
									  ) ? (
										<>
											<FAQ
												faqs={faq.answer}
												subheading="Months"
											/>
										</>
									) : (
										<>
											{categories.map((item, index) =>
												faq.answer.includes(item) ? (
													<p
														key={index}
														style={{
															display: "flex",
															alignItems:
																"center",
														}}
													>
														<DoneIcon
															style={{
																marginRight:
																	"10px",
																color: "green",
															}}
														/>{" "}
														{item}
													</p>
												) : (
													<p
														key={index}
														style={{
															display: "flex",
															alignItems:
																"center",
														}}
													>
														<PendingIcon
															style={{
																marginRight:
																	"10px",
																color: "red",
															}}
														/>{" "}
														{item}
													</p>
												)
											)}
										</>
									)}
								</Answer>
							</FAQContainer>
						))}
					</FAQSContainer>
				</FAQContent>
			</Content>
		</Container>
	);
};

export default FAQ;
